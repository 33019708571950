import { useEffect, useState } from "react";
import UserApi from "../api/User";
import JWT from "jwt-decode";
import Swal from "sweetalert2";
import $ from "jquery";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";

const Otp = () => {
  const [OTP, setOTP] = useState("");
  const [mobileno, setMobileno] = useState("");
  const history = useHistory();

  useEffect(() => {
    getMobileNumber();
  }, []);

  const getMobileNumber = async () => {
    try {
      const mobileNumber = await localStorage.getItem("mobileNumber");
      await setMobileno(mobileNumber);
    } catch (error) {}
  };

  const handleSubmit = async () => {
    if (OTP.length < 4) {
      // alert("Please enter four digit otp.");
      Swal.fire({
        title: "Jama Udhar",
        text: "Please enter four digit otp.",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
    } else {
      const user = {
        mobile_no: mobileno,
        otp: OTP,
      };

      const result = await UserApi.checkOTP(user);

      if (!result.ok) {
        // alert("Please try again!");
        Swal.fire({
          title: "Jama Udhar",
          text: "Please try again!",
          icon: "error",
          confirmButtonClass: "btn btn-primary",
          buttonsStyling: false,
        });
      } else {
        if (result.data.error) {
          // return alert(result.data.error[0]);
          return Swal.fire({
            title: "Jama Udhar",
            text: result.data.error[0],
            icon: "error",
            confirmButtonClass: "btn btn-primary",
            buttonsStyling: false,
          });
        } else {
          const data = JWT(result.data);
          if (!data) {
            window.location = "/register";
          } else {
            try {
              await localStorage.setItem("user", JSON.stringify(data));
              if (data.TnC === "No") {
                history.push("/termNCondition", { type: "login" });
                // window.location = "/termNCondition";
              } else {
                window.location = "/home";
              }
            } catch (error) {
              // alert("Please try again later!");
              Swal.fire({
                title: "Jama Udhar",
                text: "Please try again later!",
                icon: "error",
                confirmButtonClass: "btn btn-primary",
                buttonsStyling: false,
              });
            }
          }
        }
      }
    }
  };

  $("#title").text("Otp - JamaUdhar");

  return (
    <body
      className="vertical-layout vertical-menu-modern 1-column navbar-floating footer-static bg-full-screen-image blank-page blank-page"
      data-open="click"
      data-menu="vertical-menu-modern"
      data-col="1-column"
    >
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <section
              className="row flexbox-container"
              style={{ backgroundColor: "#00706c" }}
            >
              <div className="col-xl-8 col-11 d-flex justify-content-center">
                <div
                  className="card bg-authentication rounded-0 mb-0"
                  style={{
                    paddingBottom: 50,
                    paddingTop: 10,
                    backgroundColor: "#00706c",
                  }}
                >
                  <div className="row m-0">
                    {/* <div className="col-lg-6 d-lg-block d-none text-center align-self-center px-1 py-0">
                      <img
                        src="../../../app-assets/images/pages/login.png"
                        alt="branding logo"
                      />
                    </div> */}
                    <div
                      className="col-lg-12 col-12 p-0"
                      style={{ backgroundColor: "#00706c" }}
                    >
                      <div
                        className="card rounded-0 mb-0 px-2"
                        style={{ backgroundColor: "#00706c" }}
                      >
                        <div
                          className="card-header"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="card-title">
                            <img
                              src="../../../app-assets/images/logo/JU.png"
                              alt="png"
                              height="100"
                            />
                          </div>
                        </div>
                        <div
                          className="card-header pb-1"
                          style={{ justifyContent: "center" }}
                        >
                          <div className="card-title">
                            <h4
                              className="mb-0"
                              style={{
                                color: "white",
                              }}
                            >
                              Enter OTP
                            </h4>
                          </div>
                        </div>
                        <p className="px-2"></p>
                        <div className="card-content">
                          <div className="card-body pt-1">
                            {/* <form action="index.html"> */}
                            {/* <fieldset className="form-label-group form-group position-relative has-icon-left"> */}

                            {/* <input
                                type="text"
                                className="form-control"
                                id="otp"
                                onChange={(e) => setOTP(e.target.value)}
                                placeholder="OTP"
                                maxLength="4"
                                required
                              /> */}
                            {/* <div className="form-control-position">
                                <i className="feather icon-user"></i>
                              </div>
                              <label for="otp">Enter Otp</label> */}
                            {/* </fieldset> */}

                            <OtpInput
                              value={OTP}
                              onChange={(e) => setOTP(e)}
                              numInputs={4}
                              inputStyle={{
                                width: 40,
                                height: 45,
                                // borderColor: "#707070",
                                color: "white",
                                marginLeft: 10,
                                marginRight: 10,
                                background: "transparent",
                                outline: "none",
                                border: "none",
                                borderBottom: "2px #fff solid",
                                fontSize: 19,
                                inputMode: "numeric",
                              }}
                              isInputNum={true}
                            />

                            <div className="form-group d-flex justify-content-between align-items-center">
                              <div className="text-left">
                                {/* <fieldset className="checkbox">
                                    <div className="vs-checkbox-con vs-checkbox-primary">
                                      <input type="checkbox" />
                                      <span className="vs-checkbox">
                                        <span className="vs-checkbox--check">
                                          <i className="vs-icon feather icon-check"></i>
                                        </span>
                                      </span>
                                      <span className="">Remember me</span>
                                    </div>
                                  </fieldset> */}
                              </div>
                            </div>
                            <div
                              className="card-header pb-1"
                              style={{ justifyContent: "center" }}
                            >
                              <div className="card-title">
                                <h4 className="mb-0">
                                  <button
                                    type="button"
                                    className="btn btn-inline btn-center"
                                    style={{
                                      backgroundColor: "white",
                                      color: "#00706c",
                                    }}
                                    onClick={() => handleSubmit()}
                                  >
                                    <b>Next</b>
                                  </button>
                                </h4>
                              </div>
                            </div>
                            {/* <button
                              type="button"
                              className="btn btn-primary btn-inline"
                              onClick={() => handleSubmit()}
                            >
                              Next
                            </button> */}
                            {/*</form>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 d-flex justify-content-center">
                <a target={"_blank"} href={"https://jamaudhar.in/terms-conditions.html"} style={{color:"white"}}> Terms & Conditions</a>&nbsp;&nbsp;&nbsp;
                <a target={"_blank"} href={"https://jamaudhar.in/privacy-policy.html"} style={{color:"white"}}> Privacy Policy</a>&nbsp;&nbsp;&nbsp;
                <a target={"_blank"} href={"https://jamaudhar.in/terms-conditions.html#refund"} style={{color:"white"}}>Refund/Cancellation Policy</a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </body>
  );
};

export default Otp;
