import { useEffect } from "react";

const LogOut = () => {
  useEffect(() => {
    logoutUser();
  }, []);

  const logoutUser = async () => {
    await localStorage.removeItem("user");
    await localStorage.clear();
    window.location.replace("/");
  };

  return <></>;
};

export default LogOut;
