import $ from "jquery";

const SideBar = () => {
  return (
    <div
      className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
      data-scroll-to-active="true"
    >
      <div className="navbar-header expanded">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item mr-auto">
            <a className="navbar-brand" href="/home">
              <div className="brand-logo" style={{ height: 50, width: 35 }}>
                <img
                  src="../../../app-assets/images/logo/JU.png"
                  alt="Logo"
                  height="40"
                />
              </div>
              <h2 className="brand-text mb-0">Jama-Udhar</h2>
            </a>
          </li>
          <li className="nav-item nav-toggle" style={{ paddingTop: 12 }}>
            <a
              className="nav-link modern-nav-toggle pr-0"
              data-toggle="collapse"
            >
              <i className="feather icon-x d-block d-xl-none font-medium-4 primary toggle-icon feather icon-disc"></i>
              <i
                className="toggle-icon feather icon-disc font-medium-4 d-none d-xl-block collapse-toggle-icon primary"
                data-ticon="icon-disc"
              ></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="shadow-bottom"></div>
      <div className="main-menu-content">
        <ul
          className="navigation navigation-main"
          id="main-menu-navigation"
          data-menu="menu-navigation"
        >
          {/* <li>
            <a className="nav-link" href="/home">
              <i className="feather icon-home"></i>
              <span className="menu-title" data-i18n="Dashboard">
                Dashboard
              </span>
              <span className="badge badge badge-warning badge-pill float-right mr-2">
              </span>
            </a>
          </li> */}
          <li className="navigation-header">
            <span>Account Detail</span>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/membershipDetail">
              <img
                src="./app-assets/images/jamaUdhar/AllMembership.png"
                whiteimg={"whiteAllMembership.png"}
                style={{ height: 35, width: 35, marginRight: 10 }}
                alt="AllMembership"
              />
              {/* <i className="feather icon-mail"></i> */}
              <span className="menu-title" data-i18n="Email">
                All Membership
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/secondaryList">
              <img
                src="./app-assets/images/jamaUdhar/SecondaryAccounts.png"
                whiteimg={"whiteSecondaryAccounts.png"}
                style={{ height: 35, width: 35, marginRight: 10 }}
                alt="SecondaryAccounts"
              />
              {/* <i className="feather icon-message-square"></i> */}
              <span className="menu-title" data-i18n="Chat">
                Secondary Accounts
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/ContactUs">
              <img
                src="./app-assets/images/jamaUdhar/AnyQuery.png"
                whiteimg={"whiteAnyQuery.png"}
                style={{ height: 35, width: 35, marginRight: 10 }}
                alt="AnyQuery"
              />
              {/* <i className="feather icon-check-square"></i> */}
              <span className="menu-title" data-i18n="Todo">
                Any Query
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/ContactDetail">
              <img
                src="./app-assets/images/jamaUdhar/ContactUs.png"
                whiteimg={"whiteContactUs.png"}
                style={{ height: 35, width: 35, marginRight: 10 }}
                alt="ContactUs"
              />
              {/* <i className="feather icon-check-square"></i> */}
              <span className="menu-title" data-i18n="Todo">
                Contact Us
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/logOut">
              <img
                src="./app-assets/images/jamaUdhar/LogOut.png"
                whiteimg={"whiteLogOut.png"}
                style={{ height: 35, width: 35, marginRight: 10 }}
                alt="LogOut"
              />
              {/* <i className="feather icon-check-square"></i> */}
              <span className="menu-title" data-i18n="Todo">
                Log Out
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

setTimeout(function () {
  $("a[href='" + window.location.pathname + "']")
    .parent()
    .addClass("active");
  var whiteimg = $("a[href='" + window.location.pathname + "']")
    .children("img")
    .attr("whiteimg");
  $("a[href='" + window.location.pathname + "']")
    .children("img")
    .attr("src", "./app-assets/images/jamaUdhar/" + whiteimg);
}, 1000);

export default SideBar;
