import $ from "jquery";
import { useEffect, useState } from "react";
import UserApi from "../api/User";
import Swal from "sweetalert2";

const TermNCondition = (route) => {
  const [checked, setChecked] = useState(false);
  const [user, setUser] = useState();
  const userType = route.location.state.type;

  useEffect(() => {
    userExistOrNot();
  }, []);

  const userExistOrNot = async () => {
    try {
      const user = await localStorage.getItem("user");
      await setUser(JSON.parse(user));
      if (!user) {
        window.location = "/";
      }
    } catch (error) {}
  };

  const handleSubmit = async () => {
    if (!checked) {
      Swal.fire({
        title: "Jama Udhar",
        text: "Please accept terms & conditions.",
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
      return;
    } else {
      const users = {
        user_id: user.id,
        TnC: "Yes",
        sub_mobile_no: user.sub_mobile_no
      };
      const result = await UserApi.editUser(users);
      console.log(result);
      if (!result.ok) {
        return alert("Could not edit user. Please try again");
      } else {
        if (result.data === "success") {
          if (userType === "register") {
            window.location = "jamaudharweybeejuw://";
          } else {
            window.location = "jamaudharweybeejuw://";
          }
        } else if (result.data.error) {
          return alert(result.data.error[0]);
        }
      }
    }
  };

  $("#title").text("Terms & Conditions - JamaUdhar");

  return (
    <>
      {/* <Header /> */}
      <body
        className="vertical-layout 2-columns navbar-floating footer-static pace-done vertical-menu-modern menu-collapsed"
        style={{ backgroundColor: "#00706c" }}
      >
        <div className="app-content content">
          <div className="content-overlay"></div>
          {/* <div className="header-navbar-shadow"></div> */}
          <div className="content-wrapper">
            <div className="content-header row">
              <div className="content-header-left col-md-9 col-12 mb-2">
                <div className="row breadcrumbs-top">
                  <div className="col-12">
                    <h2
                      className="content-header-title float-left mb-0"
                      style={{ color: "white" }}
                    >
                      Terms & Conditions
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-body">
              <div className="offset-sm-1 col-sm col-sm-10">
                <div className="card" style={{ height: "100%" }}>
                  {/* <div className="card-header">
                  <h4 className="card-title">Query</h4>
                </div> */}
                  <div className="card-content">
                    <div
                      className="card-body"
                      style={{ backgroundColor: "white" }}
                    >
                      <div className="offset-sm-1 col-sm col-sm-10">
                        <div className="card">
                          <div className="card-header">
                            <strong className={"card-text"}>
                              TERMS AND CONDITIONS OF USE
                            </strong>
                            {/*<i className="feather icon-more-horizontal cursor-pointer"></i>*/}
                          </div>
                          <div
                            className="card-body"
                            style={{
                              height: "300px",
                              width: "auto",
                              overflowY: "auto",
                            }}
                          >
                            <p>
                              Jama Udhar (the &ldquo;Company&rdquo;) operates a
                              web/mobile application (the &ldquo;App&rdquo;)
                              available on Google Play Store, iOS and other
                              similar platforms and also operates a website
                              https://jamaudhar.in/ (the &ldquo;Website&rdquo;)
                              . The App and the Website shall be together
                              referred to as the &ldquo;Platform&rdquo;. These
                              terms and conditions (&ldquo;Terms&rdquo;) govern
                              the use of or access to the Platform and the
                              Services (as defined below).&nbsp;
                            </p>

                            <p>
                              These Terms constitute a binding and enforceable
                              legal contract between the Company and a User (as
                              defined below) or any end user of the Services
                              (collectively, &ldquo;you&rdquo;). You represent
                              and warrant that you (a) have full legal capacity
                              and authority to agree and bind yourself to these
                              Terms, (b) are 18 (eighteen) years of age or
                              older, and (c) are an Indian resident. If you
                              represent an entity, organization, or any other
                              legal person, you confirm and represent that you
                              have the necessary power and authority to bind
                              such entity, organization, or legal person to
                              these Terms.&nbsp;
                            </p>
                            <p>
                              These Terms also include our privacy policy,
                              available at (&ldquo;Privacy Policy&rdquo;) and
                              any internal guidelines, supplementary terms,
                              policies, or disclaimers made available or issued
                              by us from time to time. By continuing to access
                              or use the Platform, or any Service on the
                              Platform, you signify your acceptance of the
                              Terms. Further, you understand that the Platform
                              is intended for the use of Indian residents only
                              and by your continuing access and/use of the
                              Platform shall be construed as a deemed
                              declaration that you are an Indian resident.
                              &nbsp;&nbsp;
                            </p>
                            <p>
                              The Company reserves the right to make changes to
                              these Terms by posting the new/updated version and
                              your continued use and/or non-deletion of the
                              Platform shall indicate your agreement to such
                              changes. Accordingly, we encourage you to kindly
                              continue to review the Terms whenever accessing or
                              using the Platform so as to be abreast with the
                              changes that we may be carrying out to these
                              Terms.&nbsp;
                            </p>
                            <p>
                              By using the Services, you agree that you have
                              read, understood, and are bound by these Terms,
                              and that you comply with the requirements listed
                              herein. If you do not agree to all of these Terms
                              or comply with the requirements herein, please do
                              not access the Platform or use the Services.&nbsp;
                            </p>
                            <p>&nbsp;</p>
                            <strong>* SERVICES&nbsp;</strong>
                            <p>&nbsp;</p>
                            <p>
                              The Platform provides an online digital ledger
                              book / record book wherein the users
                              (&ldquo;Users&rdquo;), can record their ledger
                              entries with their respective customers. For the
                              purposes of this clause, Services would include
                              any other future services the Company
                              provides/proposes to provide.&nbsp;
                            </p>
                            <p>
                              The company is not liable to any error in
                              calculation error in the website.
                            </p>
                            <p>&nbsp;</p>
                            <p>
                              <br />
                            </p>
                            <strong>* ON-BOARDING</strong>
                            <p>&nbsp;</p>
                            <p>
                              1. To avail the Services, a User would be required
                              to create a profile/sign-up on the Platform
                              (&ldquo;Profile&rdquo;) using his/her email ID and
                              phone number among other details. In addition to
                              setting up username and password to create the
                              Profile, the User will be required to furnish
                              certain details, including but not limited to
                              phone numbers and details of its customers and
                              businesses. The User warrants that all information
                              furnished in connection with its Profile is and
                              shall remain accurate and true in all respects.
                              The User further agrees and undertakes to promptly
                              update its details on the Platform in the event of
                              any change or modification of such details.
                            </p>
                            <p>
                              2. The User is solely responsible for maintaining
                              the security and confidentiality of its username
                              and password and agrees to immediately notify the
                              Company in writing at Jama Udhar of any disclosure
                              or unauthorized use of its Profile or any other
                              breach of security with respect to its Profile.
                            </p>
                            <p>
                              3. The User expressly agrees to be liable and
                              accountable for all activities that take place
                              through its Profile in furtherance of the use of
                              Service or otherwise. The Company expressly
                              excludes any liability for any unauthorized access
                              to a User&rsquo;s Profile. &nbsp;
                            </p>
                            <p>
                              4. The User agrees to receive communications from
                              the Company regarding: (i) information relating to
                              transactions recorded on the Platform; (ii)
                              requests for payment; (iii) information about the
                              Company and the Services; (iv) promotional offers
                              and services from the Company and its third party
                              partners, and (v) any other matter in relation to
                              the Services.&nbsp;
                            </p>
                            <p>&nbsp;</p>
                            <strong>* TRANSACTION INFORMATION</strong>
                            <p>&nbsp;</p>
                            <p>
                              1. The Users may upload information relating to
                              transactions with their customers or relating to
                              their businesses, including the sale of goods or
                              services, costs, amounts paid and payable, and
                              details of goods and services, on the Platform
                              (such information is referred to as
                              &ldquo;Transaction Information&rdquo;).
                              Transaction Information may be exchanged between
                              the Users and their customers through telephonic
                              calls, text message, WhatsApp, email, or other
                              electronic mediums that would depend on the
                              contact details of Users and their customers
                              provided on the Platform.
                            </p>
                            <p>
                              2. At the time of creating or uploading the first
                              Transaction Information with respect to their
                              customers, the User shall inform such customers of
                              its use of the Platform to record such Transaction
                              Information and Transaction Information related to
                              future transactions and seek such customer&rsquo;s
                              express consent in this regard and to:
                            </p>
                            <p>
                              1. The creation of a profile of the customer on
                              the Platform, which will require sharing such
                              customer&rsquo;s phone number and contact details
                              with the Company;
                            </p>
                            <p>
                              <br />
                            </p>
                            <p>
                              2. receive communications from the Company
                              regarding: (A) information relating to their
                              transactions recorded on the Platform; (B)
                              requests for payment; (C) information about the
                              Company and the Services; (D) promotional offers
                              and services from the Company and its third-party
                              partners, and (E) any other matter in relation to
                              the Services.&nbsp;
                            </p>
                            <p>
                              1. If such customers fail to provide consent, or
                              withdraw consent, the User shall immediately cease
                              to use the Services in relation to such
                              customers.&nbsp;
                            </p>
                            <p>
                              2. The User shall be solely responsible for
                              obtaining such consent from its customers and the
                              Company shall assume that such consent as required
                              under clause 4(b) above is sought and received by
                              the User if the User provides details of such
                              Transaction Information relating to any of such
                              customers at any time during the use of the
                              Platform.
                            </p>
                            <p>&nbsp;</p>
                            <strong>* THIRD PARTY SERVICES</strong>
                            <p>&nbsp;</p>
                            <p>
                              1. The Services may include services, content,
                              documents, and information owned by, licensed to,
                              or otherwise made available by a third party
                              (&ldquo;Third Party Services&rdquo;) or contain
                              links to Third Party Services. Users understand
                              that Third Party Services are the responsibility
                              of the third party that created or provided it and
                              acknowledge that use of such Third-Party Services
                              is solely at their own risk.
                            </p>
                            <p>
                              2. The Company makes no representations and hereby
                              expressly excludes all warranties and liabilities
                              arising out of or pertaining to such Third-Party
                              Services, including their accuracy or
                              completeness. Further, all intellectual property
                              rights in and to Third Party Services are the
                              property of the respective third parties.
                            </p>
                            <p>
                              3. The Company enables payments via payment
                              service providers (&ldquo;PSP&rdquo;) partners and
                              the User should take care not to share his
                              personal UPI pin or OTP with any third party
                              intentionally or unintentionally. The Company
                              never solicits information such as UPI pin or OTP
                              over a call or otherwise. The Company shall not be
                              liable for any fraud due to the sharing of such
                              details by the User. The providers providing Third
                              Party Services / PSP partners shall not be liable
                              for any fraud due to sharing of such details by
                              the User. If any of such fraudulent transactions
                              occur and where the User shares his debit/credit
                              card or UPI information, the Company may share
                              relevant information of such transaction if the
                              victim approaches the Company via proper channels
                              including customer support number.&nbsp;
                            </p>
                            <strong>* USER RESPONSIBILITIES</strong>
                            <p>&nbsp;</p>
                            <p>
                              1. The User hereby represents and warrants that
                              all information that is provided by the User
                              through or in relation to the Services is valid,
                              complete, true, and correct on the date of
                              agreeing to these Terms and shall continue to be
                              valid, complete, true, and correct throughout the
                              duration of the User&rsquo;s use of the Platform.
                              The Company does not accept any responsibility or
                              liability for any loss or damage the User may
                              suffer or incur if any information, documentation,
                              material, or data provided to avail the Services
                              is incorrect, incomplete, inaccurate, or
                              misleading, or if the User fails to disclose any
                              material fact.
                            </p>
                            <p>
                              2. The User shall be solely responsible for
                              ensuring compliance with applicable laws and shall
                              be solely liable for any liability that may arise
                              due to a breach of its obligations in this regard.
                            </p>
                            <p>
                              3. The User shall extend all cooperation to the
                              Company in its defending of any proceedings that
                              may be initiated against it due to a breach of the
                              User&rsquo;s obligations or covenants under these
                              Terms.&nbsp;
                            </p>
                            <p>
                              4. While the Company uses commercially reasonable
                              efforts to provide Users with a daily backup of
                              their Transaction Information, Users should
                              regularly and independently save, backup, and
                              archive such Transaction Information.&nbsp;
                            </p>
                            <p>
                              5. The User shall not use the Services in any
                              manner except as expressly permitted in these
                              Terms. Without limiting the generality of the
                              preceding sentence, the User may not:
                            </p>
                            <p>
                              <br />
                            </p>
                            <p>
                              1. Infringe either directly or indirectly any
                              third-party proprietary rights, including but not
                              limited to copyrights, patents, trademarks, or
                              trade secrets, of any party;
                            </p>
                            <p>
                              2. except as may be provided hereunder, use in any
                              manner including copying, displaying,
                              distributing, modifying, publishing, reproducing,
                              storing, transmitting, posting, translating,
                              creating any derivative works from, or license the
                              Services;
                            </p>
                            <p>
                              3. use the Services to transmit any data or send
                              or upload any material that contains viruses,
                              trojan horses or any other harmful programmes or
                              similar computer code designed to adversely affect
                              the operation of any computer software or
                              hardware;
                            </p>
                            <p>
                              4. use any robot, spider, other automated device,
                              or manual process to monitor or copy the Platform
                              or Services or any portion thereof;
                            </p>
                            <p>
                              5. use the Services in furtherance of / to engage
                              in any activity which may be grossly harmful,
                              harassing, blasphemous, defamatory, obscene,
                              pornographic, pedophilic, libelous, invasive of
                              another&amp;#39;s privacy, hateful, or racially,
                              ethnically objectionable, disparaging, relating or
                              encouraging money laundering or gambling, or
                              otherwise unlawful in any manner whatever; or
                              unlawfully threatening or unlawfully harassing
                              including but not limited to &amp;quot;indecent
                              representation of women&amp;quot; within the
                              meaning of the Indecent Representation of Women
                              (Prohibition) Act, 1986;&nbsp;
                            </p>
                            <p>
                              6. engage in the systematic retrieval of content
                              from the Platform or Services to create or
                              compile, directly or indirectly, a collection,
                              compilation, database or directory; or
                            </p>
                            <p>7. violate applicable laws in any manner.</p>
                            <p>
                              <br />
                            </p>
                            <p>
                              1. Users who are expected to conduct proper
                              research to ensure that the goods and services
                              they send payment links for are in compliance with
                              all applicable laws and we encourage users to
                              cross-check before generating payment link for
                              prohibited content may result in the suspension or
                              removal of user&rsquo;s account.
                            </p>
                            <p>
                              2. The User shall not use the Services for / in
                              furtherance of any sale or supply of prohibited
                              products or services including but not limited to:
                            </p>
                            <p>
                              <br />
                            </p>
                            <p>1. sale of regulated goods;</p>
                            <p>
                              2. sale of counterfeit, replicas and pirated goods
                              and goods infringing any intellectual property
                              rights including pirated recordings or copies of
                              unauthorized copyrighted materials whether in
                              electronic or physical form or any other medium
                              which may not be in existence at present;
                            </p>
                            <p>
                              3. sale of tobacco and cigarettes which includes
                              cigarettes, cigars, chewing tobacco, and related
                              products;
                            </p>
                            <p>
                              4. sale of liquor or any narcotic drugs and
                              psychotropic substances;
                            </p>
                            <p>
                              5. sale / supply of any good or service which may
                              not be in public interest;
                            </p>
                            <p>
                              6. gambling or other prohibited activities; and
                            </p>
                            <p>
                              7. sale / supply of good or service which results
                              in non-compliance of any applicable law.
                            </p>
                            <p>
                              <br />
                            </p>
                            <p>
                              1. The User shall be solely responsible for
                              compliance with all the applicable laws including
                              without limitation the Prevention of Money
                              Laundering Act, 2002 and the rules made
                              thereunder. The Company shall not be responsible
                              for any claims or liability or losses that may
                              arise due to non-compliance of the anti-money
                              laundering laws in India.
                            </p>
                            <p>&nbsp;</p>
                            <strong>* INTELLECTUAL PROPERTY</strong>
                            <p>&nbsp;</p>
                            <p>
                              1. All rights, title, and interest in and to the
                              Platform and Services, including all intellectual
                              property rights arising out of the Platform and
                              Services, are owned by or otherwise lawfully
                              licensed by the Company. Subject to compliance
                              with these Terms, the Company grants the User a
                              non-exclusive, non-transferable, non-sub
                              licensable, royalty-free, revocable, and limited
                              licence to use the Platform and Services in
                              accordance with these Terms and its written
                              instructions issued from time to time.
                            </p>
                            <p>
                              2. The User should assume that everything the User
                              sees or reads on the Platform is protected under
                              the Indian Copyright Act, 1957 and other
                              intellectual property laws of India and may not be
                              used except with the prior written permission of
                              the Company.
                            </p>
                            <p>
                              3. The Company may freely use, copy, disclose,
                              publish, display, distribute without any payment
                              of royalty, acknowledgement, prior consent, or any
                              other form of restriction arising out of the
                              User&rsquo;s intellectual property rights.&nbsp;
                            </p>

                            <p>
                              4. Except as stated in these Terms, nothing in
                              these Terms should be construed as conferring any
                              right in or license to the Company&rsquo;s or any
                              third party&rsquo;s intellectual rights.
                            </p>
                            <p>
                              5. The contents of this Platform, including but
                              not limited to the text and images herein and
                              their arrangements, unless otherwise noted, are
                              copyright- protected in the whole and every part
                              of this Platform and the same belongs to the
                              Company and may not be used, sold, licensed,
                              copied or reproduced in whole or in part in any
                              manner or form or in or on any media to any person
                              without the prior written consent of the Company.
                            </p>
                            <p>&nbsp;</p>
                            <strong>* TERM AND TERMINATION</strong>
                            <p>&nbsp;</p>
                            <p>
                              1. These Terms shall remain in effect unless
                              terminated in accordance with the terms hereunder.
                            </p>
                            <p>
                              2. The Company may terminate a User&rsquo;s access
                              to or use of the Services, or any portion thereof,
                              immediately and at any point, at its sole
                              discretion, if the User violates or breaches any
                              of its obligations, responsibilities, or covenants
                              under these Terms.&nbsp;
                            </p>
                            <p>
                              3. Upon termination these Terms shall terminate,
                              except for those clauses that expressly or are
                              intended to survive termination or expiry.
                            </p>
                            <p>
                              4. Notwithstanding anything to the contrary
                              contained in the Terms, upon termination of a
                              User&rsquo;s access to or use of the Services, all
                              amounts or outstanding monies due by you in
                              relation to your use of or access to the Services
                              shall become immediately payable.
                            </p>
                            <p>&nbsp;</p>
                            <strong>* DISCLAIMERS AND WARRANTIES</strong>
                            <p>&nbsp;</p>
                            <p>
                              1. The use of the Services is at your sole risk.
                            </p>
                            <p>
                              2. You acknowledge and agree that the Company is
                              not engaged in the provision, grant, or
                              disbursement of any financial product. The Company
                              is not and will not be responsible for any claim
                              or for any damages suffered, whether by the Users,
                              the customers of the Users or any other person or
                              party, that are related, directly or indirectly,
                              to or arise out of the same including any payments
                              made by the User or by the customers of the User
                              using the payment link generated using the
                              Platform. The User further agrees and undertakes
                              to retain proof of sale documentation (in
                              electronic or physical form) in connection with
                              each payment link it generates or sends to
                              customers.
                            </p>
                            <p>
                              3. To the extent permitted by applicable law, the
                              Services are provided on an &ldquo;as is&rdquo;
                              and &ldquo;as available&rdquo; basis. The Company
                              does not warrant that operation of the Services
                              will be uninterrupted or error free or that the
                              functions contained in the Services will meet your
                              requirements.
                            </p>
                            <p>
                              4. To the fullest extent permissible under
                              applicable law, the Company expressly disclaims
                              all warranties of any kind, express or implied,
                              arising out of the Services, including warranties
                              of merchantability, fitness for a particular
                              purpose, satisfactory quality, accuracy, title and
                              non-infringement, compatibility, applicability,
                              usability, appropriateness, and any warranty that
                              may arise out of course of performance, course of
                              dealing, or usage of trade.
                            </p>
                            <p>
                              5. Jama Udhar is an independent firm, not merged
                              or working for any certified/non-certified vendor.
                            </p>
                            <p>
                              6. You hereby accept full responsibility for any
                              consequences that may arise from your use of the
                              Services, and expressly agree and acknowledge that
                              the Company shall have absolutely no liability
                              with respect to the same.
                            </p>
                            <p>
                              7. To the fullest extent permissible by law, the
                              Company, its affiliates, and its related parties
                              each disclaim all liability to you for any loss or
                              damage arising out of or due to:&nbsp;
                            </p>
                            <p>
                              <br />
                            </p>
                            <p>
                              1. your use of, inability to use, or availability
                              or unavailability of the Services, including any
                              Third Party Services;
                            </p>
                            <p>
                              2. the occurrence or existence of any defect,
                              interruption, or delays in the operation or
                              transmission of information to, from, or through
                              the Services, communications failure, theft,
                              destruction or unauthorized access to the
                              Company&rsquo;s records, programmes, services,
                              server, or other infrastructure relating to the
                              Services;
                            </p>
                            <p>or</p>
                            <p>
                              3. the failure of the Services to remain
                              operational for any period of time.
                            </p>
                            <p>
                              <br />
                            </p>
                            <p>
                              1. Notwithstanding anything to the contrary
                              contained herein, neither the Company nor any of
                              its affiliates or related parties shall have any
                              liability to you or any third party for any
                              indirect, incidental, special or consequential
                              damages or any loss of revenue or profits arising
                              under, directly or indirectly, or relating, in any
                              manner whatsoever, to these Terms or the Services.
                              To the maximum extent permitted by law, you agree
                              to waive, release, discharge, and hold harmless
                              the Company, its affiliated and subsidiary
                              companies, its parent companies, and each of their
                              directors, officers, employees, and agents, from
                              any and all claims, losses, damages, liabilities,
                              expenses and causes of action arising out of the
                              Services.
                            </p>
                            <p>
                              2. Jama Udhar currently is not a payment
                              aggregator or payment gateway-it is enabling
                              payments for its users via third-party payments
                              service providers. Any charges levied by Jama
                              Udhar for usage are service charges.&nbsp;
                            </p>
                            <strong>* INDEMNITY</strong>
                            <p>&nbsp;</p>
                            <p>
                              You shall indemnify, defend at the Company&rsquo;s
                              option, and hold the Company, its parent
                              companies, subsidiaries, affiliates, and their
                              officers, associates successors, assigns,
                              licensors, employees, directors, agents, and
                              representatives, harmless from and against any
                              claim, demand, lawsuits, judicial proceeding,
                              losses, liabilities, damages and costs (including,
                              without limitation, from all damages, liabilities,
                              settlements, costs and attorneys&rsquo; fees) due
                              to or arising out of your access to the Services,
                              use of the Services, violation of these Terms or
                              any infringement by any third party who may use
                              your account with the Company, of these
                              Terms.&nbsp;
                            </p>
                            <p>&nbsp;</p>
                            <strong>* CONSENT TO USE DATA</strong>
                            <p>&nbsp;</p>
                            <p>
                              1. You agree that the Company and any third-party
                              service providers it engages, may, in accordance
                              with its Privacy Policy, collect and use your
                              information and technical data and related
                              information.
                            </p>
                            <p>
                              2. The Company may use information and data
                              pertaining to your use of the Services for
                              analytics, trends&rsquo; identification, and
                              purposes of statistics to further enhance the
                              effectiveness and efficiency of the Platform.
                            </p>
                            <p>
                              3. Subject to applicable laws, the Company may be
                              directed by law enforcement agencies or the
                              government and related bodies to disclose data in
                              relation to Users in connection with criminal
                              proceedings. You understand and agree that in such
                              instances, the Company shall have the right to
                              share such data with relevant agencies or bodies.
                            </p>
                            <p>&nbsp;</p>
                            <strong>* MAINTENANCE OF RECORDS</strong>
                            <p>&nbsp;</p>
                            <p>
                              You shall maintain the records of all payment
                              transactions on the Platform independently of the
                              Platform (by way physical copies et cetera) and
                              the Company reserves the right to seek copies of
                              such records for their own use including record
                              keeping.&nbsp;
                            </p>
                            <p>&nbsp;</p>
                            <strong>* FEES/CHARGES</strong>
                            <p>&nbsp;</p>
                            <p>
                              The Company reserves the right to charge
                              convenience fee for the Services and non-payment
                              may result in denial of Services.&nbsp;
                            </p>
                            <p>&nbsp;</p>
                            <strong>* MODIFICATION</strong>
                            <p>&nbsp;</p>
                            <p>
                              The Company reserves the right at any time to add,
                              modify or discontinue, temporarily or permanently,
                              the Services (or any part thereof) with or without
                              cause.
                            </p>

                            <p>
                              The Company shall not be liable for any such
                              addition, modification, suspension or
                              discontinuation of the Services.&nbsp;
                            </p>
                            <p>&nbsp;</p>
                            <strong>
                              * JURISDICTION, GOVERNING LAWS, AND DISPUTE
                              RESOLUTION
                            </strong>
                            <p>&nbsp;</p>
                            <p>
                              These Terms shall be governed by and construed and
                              enforced in accordance with the laws of India.
                              Subject to other provisions in this Clause, courts
                              in Gujarat shall have exclusive jurisdiction over
                              all issues arising out of these Terms or the use
                              of the Services.&nbsp;
                            </p>
                            <p>
                              Any controversies, conflicts, disputes, or
                              differences arising out of these Terms shall be
                              resolved by arbitration in Bangalore in accordance
                              with the Arbitration and Conciliation Act, 1996
                              for the time being in force, which is deemed to be
                              incorporated by reference in this Clause. The
                              tribunal shall consist of 1 (one) arbitrator
                              appointed by the Company. The language of the
                              arbitration shall be English.&nbsp;
                            </p>
                            <p>
                              The parties to the arbitration shall keep the
                              arbitration confidential and not disclose to any
                              person, other than on a need to basis or to legal
                              advisors, unless required to do so by law. The
                              decision of the arbitrator shall be final and
                              binding on all the Parties hereto. Each party to
                              the arbitration shall bear its own costs with
                              respect to any dispute.&nbsp;
                            </p>
                            <p>&nbsp;</p>
                            <strong>* MISCELLANEOUS PROVISIONS</strong>
                            <p>&nbsp;</p>
                            <p>
                              <br />
                            </p>
                            <p>
                              1. Modification &ndash; The Company reserves the
                              right at any time to modify these Terms and to add
                              new or additional terms or conditions on use of
                              the Services. Such modifications and additional
                              terms and conditions will be communicated to you
                              and, unless expressly rejected (in which these
                              Terms shall terminate), will be effective
                              immediately and will be incorporated into these
                              Terms. In the event you refuse to accept such
                              changes, these Terms will terminate.
                            </p>
                            <p>
                              2. Severability - If any provision of these Terms
                              is determined by any court or other competent
                              authority to be unlawful or unenforceable, the
                              other provisions of these Terms will continue in
                              effect. If any unlawful or unenforceable provision
                              would be lawful or enforceable if part of it were
                              deleted, that part will be deemed to be deleted,
                              and the rest of the provision will continue in
                              effect (unless that would contradict the clear
                              intention of the clause, in which case the
                              entirety of the relevant provision will be deemed
                              to be deleted).
                            </p>
                            <p>
                              3. Assignment - You shall not license, sell,
                              transfer or assign your rights, obligations, or
                              covenants under these Terms in any manner without
                              the Company&rsquo;s prior written consent. The
                              Company may grant or withhold this consent in its
                              sole discretion and subject to any conditions it
                              deems appropriate. The Company may assign its
                              rights to any of its affiliates, subsidiaries, or
                              parent companies, or to any successor in interest
                              of any business associated with the Services
                              without any prior notice to you.&nbsp;
                            </p>
                            <p>
                              4. Notices - All notices, requests, demands, and
                              determinations for the Company under these Terms
                              (other than routine operational communications)
                              shall be sent to sales@jamaudhar.in
                            </p>
                            <p>
                              5. Third Party Rights - No third party shall have
                              any rights to enforce any terms contained herein.
                            </p>
                            <p>
                              6. Translations &ndash;The Company may provide you
                              with translated versions of these Terms solely to
                              assist you with understanding these Terms in
                              greater detail. The English version of these Terms
                              shall be controlling in all respects. In the event
                              of any inconsistency between the English version
                              of these Terms and any translated version, the
                              terms of the English version shall prevail.&nbsp;
                            </p>

                            <p>&nbsp;</p>
                            <strong>* REFUND/CANCELLATION</strong>
                            <p>&nbsp;</p>
                            <p>
                              <br />
                            </p>
                            <p>1. Membership is strictly non-refundable.</p>
                            <p>
                              2. Transactions cannot be cancelled after
                              initiating the transaction. If the User has
                              entered the wrong number ID, then the Company is
                              not a liable party in the transaction.
                            </p>
                            <p>
                              3. All transactions are real-time and if there is
                              any delay in the end of the transaction then the
                              User can check with support team of Jama Udhar
                              after 30 mins cool period.
                            </p>
                            <p>
                              <br />
                            </p>
                            <strong>* Debit/Credit Card</strong>
                            <p>&nbsp;</p>
                            <p>
                              <br />
                            </p>
                            <p>
                              1. If the User has any issues related to
                              transactions linked with Debit/Credit card, then
                              the User must report the same to the
                              Company&rsquo;s call center 9090050095 The Company
                              will respond and try to resolve the issue in the
                              given escalation matrix as per the Company&rsquo;s
                              internal processes and policies.
                            </p>
                            <p>
                              2. All refunds will be processed by following the
                              validation of the transaction from case to case
                              basis in the given legal framework.
                            </p>
                            <p>
                              3. Escalation matrix remains the same for the
                              Refunds/Cancellation for all the transactions.
                            </p>
                            <p>
                              4. The Company allows the Users to export the PDF
                              from its account which the User can share with its
                              customer as a statement/reminder for collections.
                            </p>
                          </div>
                          <div
                            className={"card-footer"}
                            style={{
                              backgroundColor: "white",
                              height: "100px",
                            }}
                          >
                            <div className="form-group row">
                              <div className="col-12">
                                <fieldset className="checkbox">
                                  <div className="vs-checkbox-con vs-checkbox-primary">
                                    <input
                                      type="checkbox"
                                      defaultChecked={checked}
                                      onChange={() => setChecked(!checked)}
                                    />
                                    <span className="vs-checkbox">
                                      <span className="vs-checkbox--check">
                                        <i className="vs-icon feather icon-check"></i>
                                      </span>
                                    </span>
                                    <span className="">
                                      {" "}
                                      I accept the terms & conditions.
                                    </span>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                            <div className={"form-group row"}>
                              <button
                                onClick={() => handleSubmit()}
                                className={"btn btn-primary"}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default TermNCondition;
