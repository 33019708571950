import Header from "./Header";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import UserApi from "../api/User";
import moment from "moment";
import $ from "jquery";

const Home = () => {
  const [ActiveSubscription, setActiveSubscription] = useState([]);
  const [user, setUser] = useState();

  useEffect(() => {
    userExistOrNot();
    getSubscriptionList();
  }, []);

  const userExistOrNot = async () => {
    try {
      const user = await localStorage.getItem("user");
      await setUser(JSON.parse(user));
      if (!user) {
        window.location = "/";
      }
    } catch (error) {}
  };

  const getSubscriptionList = async () => {
    const results = await localStorage.getItem("user");
    const userDetail = JSON.parse(results);

    const users = {
      mobile_no: userDetail.mobile_no,
      status: "",
    };
    const result = await UserApi.getSubscriptionList(users);

    if (!result.ok) {
      alert("Please try again later");
    } else {
      setActiveSubscription(result.data);
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayRazorPay = async (orderDetail, subscriptionDetail) => {
    const user = await localStorage.getItem("user");
    const userDetail = JSON.parse(user);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: "rzp_live_KiFX7wdUKtEu8U", // Enter the Key ID generated from the Dashboard
      amount: orderDetail.amount,
      currency: orderDetail.currency,
      name: userDetail.first_name + " " + userDetail.last_name,
      description: subscriptionDetail.plan_name,
      order_id: orderDetail.order_id,
      handler: async function (response) {
        const data = {
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        const result = await UserApi.getPaymentDetail(data);

        if (!result.ok) {
          return alert("Please try again later");
        } else {
          console.log(result.data);
        }
      },
      prefill: {
        name: userDetail.first_name + " " + userDetail.last_name,
        email: "xyz@xyz.com",
        contact: userDetail.mobile_no,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#00776c",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  console.log(ActiveSubscription);
  // const subscriptionCheckout = async (subscriptionDetail) => {
  //   console.log(subscriptionDetail);
  //   const data = localStorage.getItem("user");
  //   const userDetail = JSON.parse(data);

  //   const SelectMobileNo = [];
  //   SelectMobileNo.push(subscriptionDetail.user_mobile_no);

  //   const subscription = {
  //     subscriptionplan_id: subscriptionDetail.subscriptionplan_id,
  //     userid: userDetail.id,
  //     secondaryuserid: JSON.stringify(SelectMobileNo),
  //   };

  //   const result = await UserApi.subscriptionCheckout(subscription);
  //   console.log(result);
  //   if (!result.ok) {
  //     return alert("Please try again later.");
  //   } else {
  //     if (result.data.success) {
  //       displayRazorPay(result.data.success, subscriptionDetail);
  //       return;
  //     } else if (result.data.error) {
  //       return Swal.fire({
  //         title: "Jama Udhar",
  //         text: result.data.error[0],
  //         icon: "error",
  //         confirmButtonClass: "btn btn-primary",
  //         buttonsStyling: false,
  //       });
  //     }
  //   }
  // };

  $("#title").text("Dashboard - JamaUdhar");
  console.log(user);
  return (
    <>
      <Header />
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div
                  className="col-12 d-md-none"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 15,
                  }}
                >
                  <a
                    href="jamaudharweybeejuw://"
                    className="btn btn-primary float-left white"
                  >
                    Open In App
                  </a>
                </div>
                <div className="col-12">
                  <h2 className="content-header-title float-left mb-0">
                    User Detail
                  </h2>
                  {/* <div className="breadcrumb-wrapper col-12">
                    <ol className="breadcrumb"></ol>
                  </div> */}
                </div>

                {/* <Link
                  to={{
                    pathname: "/choosePlan",
                    query: { data: user },
                  }}
                >
                  <button
                    className="btn btn-primary float-left white"
                    style={{ marginTop: 25 }}
                  >
                    Choose Plan
                  </button>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="content-body">
            <section className="page-users-view">
              <div className="row">
                {/* <a href="jamaudharweybee://">Open in app</a> */}
                {/* account start */}
                <div className={"col-12"}>
                  <div
                    className="card"
                    style={{
                      backgroundImage: "url('')",
                      background: "#00776c",
                      color: "white",
                    }}
                  >
                    <div
                      className="card-header"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="card-title" style={{ color: "#ffcc58" }}>
                        {user && user.first_name + " " + user.last_name}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="users-view-image">
                          {/*<img src="../../../app-assets/images/portrait/small/avatar-s-12.jpg" className="users-avatar-shadow w-100 rounded mb-2 pr-2 ml-1" alt="avatar" />*/}
                        </div>
                        <div className="col-12 col-sm-9 col-md-6 col-lg-5">
                          <center>
                            <table>
                              {/*<tr>*/}
                              {/*  <td><h5 className="font-weight-bold" style={{"color":"white"}}>Name</h5></td>*/}
                              {/*  <td>*/}
                              {/*    /!*<h5 style={{"color":"#ffcc58"}}></h5>*!/*/}
                              {/*  </td>*/}
                              {/*</tr>*/}
                              <tr>
                                <td className="font-weight-bold">
                                  <h5
                                    className="font-weight-bold"
                                    style={{ color: "white" }}
                                  >
                                    Mobile No
                                  </h5>
                                </td>
                                <td>
                                  <h5
                                    style={{
                                      color: "#ffcc58",
                                      textAlign: "right",
                                    }}
                                  >
                                    {user && user.mobile_no}
                                  </h5>
                                </td>
                              </tr>
                              <tr>
                                <td className="font-weight-bold">
                                  <h5
                                    className="font-weight-bold"
                                    style={{ color: "white" }}
                                  >
                                    {" "}
                                    Membership
                                  </h5>
                                </td>
                                <td>
                                  <h5
                                    style={{
                                      color: "#ffcc58",
                                      textAlign: "right",
                                    }}
                                  >
                                    {user && user.subscription_status}
                                  </h5>
                                </td>
                              </tr>
                              <tr>
                                <td className="font-weight-bold">
                                  <h5
                                    className="font-weight-bold"
                                    style={{ color: "white" }}
                                  >
                                    Current Active Plan
                                  </h5>
                                </td>
                                <td>
                                  <h5
                                    style={{
                                      color: "#ffcc58",
                                      textAlign: "right",
                                    }}
                                  >
                                    {user && user.plan_name}
                                  </h5>
                                </td>
                              </tr>
                            </table>
                          </center>
                        </div>
                        <div className="col-12 col-md-12 col-lg-5">
                          <center>
                            <table className="ml-0 ml-sm-0 ml-lg-0">
                              <tbody>
                                <tr>
                                  <td className="font-weight-bold">
                                    <h5
                                      className="font-weight-bold"
                                      style={{ color: "white" }}
                                    >
                                      Account Type
                                    </h5>
                                  </td>
                                  <td>
                                    <h5
                                      style={{
                                        color: "#ffcc58",
                                        textAlign: "right",
                                      }}
                                    >
                                      Primary
                                    </h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </center>
                        </div>
                        {/*<div className="col-12">*/}
                        {/*  <a href="app-user-edit.html" className="btn btn-primary mr-1 waves-effect waves-light"><i className="feather icon-edit-1" /> Edit</a>*/}
                        {/*  <button className="btn btn-outline-danger waves-effect waves-light"><i className="feather icon-trash-2" /> Delete</button>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/* account end */}
              </div>
            </section>
            {/* <!-- Basic example and Profile cards section start --> */}
            <div className="row breadcrumbs-top">
              <div className="col-12">
                <h2 className="content-header-title float-left mb-0">
                  Current Active Plan
                </h2>
              </div>
            </div>
            <br />
            <section id="basic-examples">
              <div className="row match-height">
                {ActiveSubscription &&
                  ActiveSubscription.map((value, index) => (
                    <div className="col-xl-4 col-md-6 col-sm-12">
                      <div className="card">
                        <div className="card-content">
                          <div className="card-body">
                            <div
                              style={{
                                height: 120,
                                backgroundColor: "#00776C",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                borderTopLeftRadius: 15,
                                borderTopRightRadius: 15,
                                flexDirection: "column",
                              }}
                            >
                              <h3 style={{ color: "white" }}>
                                {" "}
                                {value.first_name + " " + value.last_name}
                              </h3>
                              {/*<h3 style={{ color: "white" }}>*/}
                              {/*  {" "}*/}
                              {/*  {value.plan_name}*/}
                              {/*</h3>*/}
                            </div>
                            <h3
                              className="mt-1"
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                            >
                              {moment().format("DD-MM-YYYY") >=
                                moment(value.subscription_end)
                                  .add(-15, "days")
                                  .format("DD-MM-YYYY") && (
                                <h5 style={{ color: "red" }}>
                                  Your membership plan is about to expire
                                </h5>
                              )}
                              {/*{value.plan_price != 0 && "₹" + value.plan_price}*/}
                              {/*{value.plan_price != 0 && (*/}
                              {/*  <Link*/}
                              {/*    to={{*/}
                              {/*      pathname: "/invoice",*/}
                              {/*      query: { data: value },*/}
                              {/*    }}*/}
                              {/*  >*/}
                              {/*    <a*/}
                              {/*      style={{*/}
                              {/*        float: "right",*/}
                              {/*      }}*/}
                              {/*    >*/}
                              {/*      <i className={"feather icon-file-text"}></i>*/}
                              {/*    </a>*/}
                              {/*  </Link>*/}
                              {/*)}*/}
                            </h3>
                            <hr className="my-1" />
                            <div
                              className="d-flex justify-content-between mt-2"
                              style={{ paddingLeft: 20, paddingRight: 20 }}
                            >
                              <h5 style={{ color: "gray" }}>Mobile Number</h5>
                              <h4>{value.sub_mobile_no}</h4>
                            </div>
                            {/*<div*/}
                            {/*  className="d-flex justify-content-between mt-2"*/}
                            {/*  style={{ paddingLeft: 20, paddingRight: 20 }}*/}
                            {/*>*/}
                            {/*  /!*<h5 style={{ color: "gray" }}>Validity upto</h5>*!/*/}
                            {/*  /!*<h4>{value.validity_days} days</h4>*!/*/}
                            {/*</div>*/}
                            <div
                              className="d-flex justify-content-between mt-2"
                              style={{ paddingLeft: 20, paddingRight: 20 }}
                            >
                              <h5 style={{ color: "gray" }}>Start Date</h5>
                              <h4>
                                {moment(value.subscription_start).format(
                                  "DD-MM-YYYY"
                                )}
                              </h4>
                            </div>
                            <div
                              className="d-flex justify-content-between mt-2"
                              style={{ paddingLeft: 20, paddingRight: 20 }}
                            >
                              <h5 style={{ color: "gray" }}>End Date</h5>
                              <h4>
                                {moment(value.subscription_end).format(
                                  "DD-MM-YYYY"
                                )}
                              </h4>
                            </div>
                          </div>
                        </div>

                        <div
                          className="card-btns"
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            marginTop: 15,
                            paddingBottom: 30,
                          }}
                        >
                          {value.subscriptionplan_id === 1 ? (
                            <Link
                              to={{
                                pathname: "/choosePlan",
                                state: { data: value },
                              }}
                            >
                              <button className="btn btn-primary float-left white">
                                Choose Plan
                              </button>
                            </Link>
                          ) : (
                            <Link
                              to={{
                                pathname: "/choosePlan",
                                state: { data: value },
                              }}
                            >
                              <button className="btn btn-primary float-left white">
                                Renew Plan
                              </button>
                            </Link>
                          )}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </section>
            {/* <!-- // Basic example and Profile cards section end -->*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
