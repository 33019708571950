import { useEffect, useState } from "react";
import Header from "./Header";
import UserApi from "../api/User";
import Swal from "sweetalert2";
import $ from "jquery";

const Invoice = (route) => {
  const [invoiveDetail, setInvoiceDetail] = useState();
  const userDetail = route.location.state.data;

  useEffect(() => {
    userExistOrNot();
    getInvoiceDetail();
  }, []);

  const userExistOrNot = async () => {
    try {
      const user = await localStorage.getItem("user");
      if (!user) {
        window.location = "/";
      }
    } catch (error) {}
  };

  const getInvoiceDetail = async () => {
    const users = {
      payment_id: userDetail.payment_id,
    };
    const result = await UserApi.getInvoice(users);
   // console.log(result);
    if (!result.ok) {
      Swal.fire({
        title: "Jama Udhar",
        text: result.data.error,
        icon: "error",
        confirmButtonClass: "btn btn-primary",
        buttonsStyling: false,
      });
    } else {
      setInvoiceDetail(result.data);
    }
  };

  function convert(current_datetime) {
    let formatted_date =
      current_datetime.getUTCDate() +
      "-" +
      (current_datetime.getUTCMonth() + 1) +
      "-" +
      current_datetime.getUTCFullYear() +
      " ";
    return formatted_date;
  }

  $("#title").text("All Membership - JamaUdhar");

  return (
    <>
      <Header />
      <div className="app-content content">
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              "@media screen{.noPrint{}.noScreen{display:none;}}@media print{.noPrint{display:none;}.noScreen{}}",
          }}
        />
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper">
          <div className="content-header row noPrint">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div className="col-12">
                  <h2 className="content-header-title float-left mb-0">
                    Invoice
                  </h2>
                  <div className="breadcrumb-wrapper col-12"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body">
            {/*invoice functionality start */}

            <section className="card invoice-page visible-print">
              <div className={"card"}>
                <div className={"card-body"}>
                  <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                      __html:
                        "\n\t.wrap{ width: 50%; margin: auto; }\n\t.header{ width: 100%; display: block;}\n\t.tag .logo{ width: 50%; display: inline-block; }\n\t.tag{ text-align: right; }\n\t.tag h1{ font-family: 'Roboto', sans-serif; color: rgb(136,136,136); font-weight: 300; }\n\t.main-part {width: 100%; display: flex; }\n\t.left{ width: 50%; display: inline-block; }\n\t.id{font-family: 'Roboto', sans-serif; border-bottom: 2px #fff solid; \n\tbackground-color: rgb(250,250,250); padding: 25px 15px; }\n\t.id span{ font-size: 12px; }\n\t.right{border-left: 2px #fff solid; width: 50%; display: inline-block; background-color: rgb(250,250,250); padding: 15px 35px; }\n\t.right p{ width: 100%; line-height: 1.5; font-family: 'Roboto', sans-serif; color: rgb(136,136,136);}\n\t.title { width: 100%; background-color: rgb(250,250,250); padding: 5px 0px; margin-top: 30px; }\n\t.title h4{ margin: 0px; padding-left: 25px; }\n\t.bill, .total{ font-family: 'Roboto', sans-serif; font-size: 14px; text-align: right; margin-top: 30px;}\n\t.bill p,.total p {border-top: 1px #e0e0e0 solid; border-bottom: 1px #e0e0e0 solid; padding: 10px; }\n\t.t2{ text-align: right; }\n\t.t2 span,.total span{ font-weight: 600; font-size: 18px; padding-left: 20px; }\n\t\n\n\t@media only screen and ( max-width: 768px){\n\t\t.wrap{ width: 90%; }\n\t\t.main-part{ display: block; }\n\t\t.left,.right{ width: 100% !important; }\n\t\t.right{ padding: 0px; }\n\t\t.right p{ padding-left: 25px; width: 70%; }\n\t} \n\n\t\n",
                    }}
                  />
                  <div className="wrap" id={"invoice"}>
                    <div className="invoice">
                      <div className="header">
                        <div
                          className="logo"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src="../../../app-assets/images/logo/JU.png"
                            alt="png"
                            height="70"
                          />
                        </div>
                        <div className="tag">
                          <h1>Invoice</h1>
                        </div>
                        {/*tag*/}
                      </div>
                      {/*header*/}
                      <div className="main-part">
                        <div className="left">
                          <div className="id">
                            <span>Receipt Number</span>
                            <br />
                            <a href="#">
                              {invoiveDetail && invoiveDetail.receipt}
                            </a>
                          </div>
                          <div className="id">
                            <span>INVOICE DATE</span>
                            <br />
                            {invoiveDetail &&
                              convert(new Date(invoiveDetail.orderdate))}
                          </div>
                          <div className="id">
                            <span>ORDER ID</span>
                            <br />
                            <a href="#">
                              {invoiveDetail && invoiveDetail.order_id}
                            </a>
                          </div>
                        </div>
                        <div className="right">
                          <p>
                            Billed to
                            <br />
                            {invoiveDetail &&
                              invoiveDetail.first_name +
                                " " +
                                invoiveDetail.last_name}
                            <br />
                            {invoiveDetail && invoiveDetail.mobile_no}
                            <br />
                          </p>
                        </div>
                      </div>
                      {/*main-part*/}
                      <div className="title">
                        <h4>
                          {invoiveDetail && invoiveDetail.plan_name}{" "}
                          <b style={{ float: "right" }}>
                            {" "}
                            ₹ {invoiveDetail && invoiveDetail.amount}
                          </b>
                        </h4>
                        <h5 style={{ marginLeft: 25 }}>
                          {userDetail &&
                            userDetail.first_name +
                              " " +
                              userDetail.last_name}{" "}
                        </h5>
                        <h5 style={{ marginLeft: 25 }}>
                          {userDetail && userDetail.sub_mobile_no}
                        </h5>
                        <h6 style={{ marginLeft: 25 }}>
                          Start Date :{" "}
                          {invoiveDetail &&
                            convert(new Date(invoiveDetail.start_date))}
                        </h6>
                        <h6 style={{ marginLeft: 25 }}>
                          End Date :{" "}
                          {invoiveDetail &&
                            convert(new Date(invoiveDetail.end_date))}
                        </h6>
                      </div>
                      {/*title*/}
                      <div className="bottom">
                        {/*<div className="bill">*/}
                        {/*  <p>Inclusive of IGST at 18%</p>*/}
                        {/*</div>*/}
                        {/*bill*/}
                        <div className="t2">
                          <p>
                            Subtotal{" "}
                            <span>
                              ₹ {invoiveDetail && invoiveDetail.amount}
                            </span>
                            <br />
                            {/* <br />
                            IGST charged at 18%<span>₹ 11</span> */}
                          </p>
                        </div>
                        <div className="total">
                          <p>
                            TOTAL
                            <span>
                              ₹ {invoiveDetail && invoiveDetail.amount}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          "font-family": "Roboto, sans-serif",
                          "font-size": 14,
                          "text-align": "left",
                          "margin-top": 30,
                        }}
                      >
                        <div className="">
                          <div className="id">
                            <span>For any query contact us</span>
                            <br />
                            <span style={{ color: "#00776c" }}>
                              303, Nakshatra Heights 150 Feet Ring Road,
                              <br /> Opp Raiya Telephone Exchange Besides Water
                              Tank,
                              <br /> Gujarat,Rajkot 360005
                              <br /> 9090050095
                            </span>
                          </div>
                        </div>
                      </div>
                      {/*bottom*/}
                      <section
                        className="invoice-print mb-1 noPrint"
                        style={{ paddingTop: 25 }}
                      >
                        <div className="row">
                          <div className="col-12 col-md-7 d-flex flex-column flex-md-row justify-content-end">
                            <button
                              onClick={() => window.print()}
                              className="btn btn-primary btn-print mb-1 mb-md-0 waves-effect waves-light"
                            >
                              <i className="feather icon-file-text"></i> Print
                            </button>
                            {/*<button className="btn btn-outline-primary  ml-0 ml-md-1 waves-effect waves-light"><i*/}
                            {/*    className="feather icon-download"></i> Download*/}
                            {/*</button>*/}
                          </div>
                        </div>
                      </section>
                    </div>
                    {/*invoice*/}
                  </div>
                  {/*wrap*/}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
